@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/bootstrap";
@import "@sweetalert2/theme-bootstrap-4/bootstrap-4.scss";

.cursor {
  cursor: pointer;
}

.row>* {
  margin-top: 0;
}

.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid #ccc !important;
  color: #777 !important;
}