/*!
 * Theme: Blue 
 * Package: DashLite v1.8.0
 * Updated: 07.23.2020
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
**/
.nk-sidebar.is-dark {
  background: #0f192a;
  border-right-color: #1c2f50;
}

.nk-sidebar.is-theme {
  background: #0a2d6c;
  border-right-color: #0f419a;
}

.is-dark .nk-sidebar-head {
  border-color: #1c2f50;
}

.is-theme .nk-sidebar-head {
  border-color: #0f419a;
}

.nk-sidebar.is-dark .user-balance-alt,
.nk-sidebar.is-theme .user-balance-alt {
  color: rgba(255, 255, 255, 0.8);
}

.nk-header {
  background: #f5f6fa;
  border-bottom-color: #e5e9f2;
}
.nk-header.is-dark:not([class*="bg-"]) {
  background: #0f192a;
}
.nk-header.is-theme:not([class*="bg-"]) {
  background: #0a2d6c;
}

.is-dark .nk-menu-link {
  color: #8699b2;
}
.is-dark .nk-menu-link:hover {
  background: #172641;
}

.is-dark .active > .nk-menu-link {
  background: #172641;
}

.is-dark .nk-menu-icon {
  color: #9faec2;
}

.is-dark .nk-menu-badge {
  color: #6facfe;
  background: #1c2f50;
}

.is-dark .nk-menu-sub .active > .nk-menu-link,
.is-dark .nk-menu-link:hover,
.is-dark .active > .nk-menu-link,
.is-dark .nk-menu-link:hover .nk-menu-icon,
.is-dark .nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.is-dark .nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon {
  color: #3c8efe;
}

.is-dark .user-name {
  color: #90a1b8;
}

.is-dark .nk-quick-nav-icon {
  color: #9faec2;
}

.is-theme .nk-menu-link {
  color: #9fc7ff;
}
.is-theme .nk-menu-link:hover {
  background: #0d3988;
}

.is-theme .active > .nk-menu-link {
  background: #0d3988;
}

.is-theme .nk-menu-icon {
  color: #c8dfff;
}

.is-theme .nk-menu-badge {
  color: #a1c9ff;
  background: #0f419a;
}

.is-theme .nk-quick-nav-icon,
.is-theme .overline-title {
  color: #a9cdff;
}

.is-theme .nk-menu-heading .overline-title {
  color: rgba(169, 205, 255, 0.8);
}

.is-theme .user-name {
  color: #c3dcff;
}

.card-tools-nav li a:before,
.nav-tabs .nav-link:after,
.progress-bar,
.dot-primary,
.alert-fill.alert-primary,
.badge-primary,
.icon-circle,
.noUi-connect,
.nk-msg-menu-item a:after,
.ui-shady .nk-msg-item.active:after,
.ui-shady .nk-msg-item.current:after,
.ui-softy .nk-msg-item.active:after,
.ui-softy .nk-msg-item.current:after {
  background: #0971fe;
}

.card-bordered.is-dark {
  border-color: #0b3175;
}

.card.is-dark {
  background: #0b3175;
}

.is-dark .nk-wg7-title,
.is-dark .nk-wg7-note {
  color: #a9cdff;
}

.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  background: #3c8efe;
}

.nk-menu-link:hover,
.nk-menu-link:hover .nk-menu-icon,
.nk-menu-item.active > .nk-menu-link .nk-menu-icon,
.nk-menu-item.current-menu > .nk-menu-link .nk-menu-icon,
.nk-menu-link:hover .count,
.nk-menu-sub .nk-menu-link:hover,
.nk-menu-sub .active > .nk-menu-link,
.nk-menu-sm .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:hover,
.nk-menu-main .nk-menu-link:focus,
.nk-menu-main .nk-menu-item.active > .nk-menu-link,
.nk-menu-main .nk-menu-item.current-menu > .nk-menu-link,
.nk-menu-main .nk-menu-item:hover > .nk-menu-link,
.is-light .nk-menu-link:hover,
.is-light .active > .nk-menu-link,
.active > .nk-menu-link,
.active > .nk-menu-link .count,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.active .nav-link,
.nk-menu-footer .nk-menu-icon,
.nk-menu-footer .nk-menu-link:hover,
.nk-footer-copyright a:hover,
.page-link:hover,
.list-plain a:hover,
.link-check li a:hover,
.link-list a:hover,
.link-list-opt a:hover,
.link-list-plain a:hover,
.link-list-menu li.active > a,
.link-list-menu a.active,
.link-list-menu a:hover,
.link-list-menu li.active > a .icon,
.link-list-menu a.active .icon,
.link-list-menu a:hover .icon,
.link-list-menu li.active > a:after,
.link-list-menu a.active:after,
.link-list-menu a:hover:after,
.list-checked li:before,
.list-step li.list-step-current:before,
.accordion-s2 .accordion-head .title,
.accordion-s3 .accordion-head .title,
.badge-outline-primary,
.badge-dim.badge-primary,
.badge-dot.badge-primary,
.badge-dim.badge-outline-primary,
.alert-primary,
.form-clip,
.form-text-hint,
.search-submit:hover,
.attach-item .icon,
.attach-download:hover span,
.nk-reply-meta-info .whom,
.nk-msg-tags li > span .icon,
.nk-msg-menu-item a:hover,
.nk-msg-menu-item.active a,
.user-balance,
.user-avatar[class*="-primary-dim"],
a,
.dropzone .dz-message-text span,
.nk-switch-icon.active,
.link-list-plain a .icon,
.chat-upload-option a,
.is-unread .chat-context .status,
.add-opt:hover .sub-text,
.add-opt:hover .icon,
.icon[class*="bg-primary-dim"],
.is-light .nk-menu-link.active,
.nk-tb-list .tb-asterisk a {
  color: #0971fe;
}

a:hover {
  color: #015edd;
}

.text-primary,
.link-primary {
  color: #0971fe !important;
}

.link-primary:hover,
a.text-primary:hover,
a.text-primary:focus,
.chat-upload-option a:hover {
  color: #015edd !important;
}

.border-primary,
.nk-kycfm-control:checked ~ .nk-kycfm-label {
  border-color: #0971fe !important;
}

.bg-lighter {
  background-color: #f5f6fa !important;
}

.bg-primary {
  background-color: #0971fe !important;
}

.bg-primary-dim {
  background-color: #e6f1ff !important;
}

.text-primary-dim {
  color: #e6f1ff !important;
}

.alert-primary,
.badge-dim.badge-outline-primary {
  background-color: #e6f1ff;
  border-color: #9dc6ff;
}

.alert-pro.alert-primary,
.badge-primary,
.sp-package-choose:checked ~ label,
.plan-control:checked + .plan-item-card,
.form-control:focus,
.form-control.focus,
.custom-file-input:focus ~ .custom-file-label,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.select2-container--default .select2-selection--single:focus,
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #0971fe;
}

.nk-order-ovwg-data.sell,
.badge-outline-primary {
  border-color: #9dc6ff;
}

.dropdown-menu-s1 {
  border-top-color: #0971fe;
}

.nk-iv-wg2-amount.ui-v2 {
  border-bottom-color: #0971fe;
}

.page-item.active .page-link,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #0971fe;
  border-color: #0971fe;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before,
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before,
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #6baafe;
}

.badge-dim.badge-primary {
  background-color: #e6f1ff;
  border-color: #e6f1ff;
}

.badge-primary.badge-dot {
  background: transparent;
}

.nk-error-head {
  background: -webkit-linear-gradient(#0971fe, #0f45a4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-switch .nav-link.active {
  background: #0971fe;
  color: #ffffff;
}

.icon-avatar {
  color: #0971fe;
  background-color: #daeaff;
}

.is-theme .nk-quick-nav-icon:before {
  background-color: #071d46;
}

.btn-primary {
  background-color: #0971fe;
  border-color: #0971fe;
}
.btn-primary:hover {
  background-color: #0167f2;
  border-color: #0163e7;
}
.btn-primary:focus {
  background-color: #0167f2;
  border-color: #0163e7;
}
.btn-primary.btn-dim {
  color: #0971fe;
  background-color: #e4efff;
  border-color: #e4efff;
}

.btn-dim.btn-outline-primary {
  color: #0971fe;
  background-color: #e4efff;
  border-color: #9dc6ff;
}
.btn-dim.btn-outline-primary.btn-white {
  background-color: #ffffff;
}

.btn-outline-primary {
  border-color: #0971fe;
  color: #0971fe;
}
.btn-outline-primary:hover {
  background-color: #0971fe;
  border-color: #0971fe;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #0169f7;
  border-color: #0167f2;
}

.btn-primary:focus,
.btn-outline-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary.btn-dim:focus,
.btn-primary.btn-dim:not(:disabled):not(.disabled):active,
.btn-primary.btn-dim:not(:disabled):not(.disabled):active:focus,
.btn-dim.btn-outline-primary:focus,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 113, 254, 0.2);
}

.btn-outline-light:focus,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 148, 174, 0.1);
}

.btn-trigger:focus {
  box-shadow: none;
}

.form-control:focus,
.form-control.focus,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-file-input:focus ~ .custom-file-label,
.select2-container--default .select2-selection--single:focus,
.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  box-shadow: 0 0 0 3px rgba(9, 113, 254, 0.1);
}

.form-control-simple:focus {
  box-shadow: none;
}

.btn-primary.btn-dim:not(:disabled):not(.disabled):hover,
.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):hover,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #0971fe;
  border-color: #0971fe;
}

.form-focus-none:focus {
  border-color: transparent;
  box-shadow: none;
}

@media (min-width: 992px) {
  .nk-menu-main > li > .nk-menu-link:before {
    background: #0971fe;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link {
    color: #a9cdff;
  }
  .is-theme .nk-menu-main > li > .nk-menu-link.nk-menu-toggle:after {
    color: rgba(169, 205, 255, 0.7);
  }
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #daeaff;
  color: #0971fe;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #5da1ff;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background-color: #0971fe;
}

.code-tag {
  color: blue;
}

.alert-pro {
  background: #ffffff;
  color: #526484;
}

.nk-menu-badge {
  color: #539cfe;
  background-color: #e6f1ff;
}

.is-theme .nk-sidebar-footer,
.nk-apps-sidebar.is-theme,
.is-theme .nk-sidebar-profile-fixed {
  background: #0a2d6c;
  border-color: #0f419a;
}

.is-theme .nk-menu-footer .nk-menu-link,
.is-theme .sub-text,
.is-theme .lead-text span {
  color: #a9cdff;
}

.nav-switch-s2 .nav-link:hover,
.nav-switch-s2 .nav-link:focus {
  color: #526484;
}

.nav-switch-s2 .nav-link.active {
  color: #364a63;
}

.active .nk-ibx-menu-text,
.active .nk-ibx-menu-item .icon,
.nk-reply-form-nav li a:hover,
.nk-reply-form-input .toggle-opt:hover {
  color: #0971fe;
}

.nk-ibx-menu li.active {
  background: #ebf4ff;
}

.nk-fmg-menu li.active {
  background: #ebf4ff;
}

.nk-file-name .asterisk .icon,
.nk-file-name-text a.title:hover,
.nk-file-link:hover .title,
.active .nk-fmg-menu-item .icon,
.active .nk-fmg-menu-text {
  color: #0971fe;
}

.nk-files-view-list .nk-file.selected {
  background-color: #e1eeff;
}

.chat.is-me .chat-msg {
  background-color: #0971fe;
}
