.header {
  &.navbar-container {
    .navbar {
      padding: 1rem;
      &[data-sticky="top"] {
        transition: all 0.2s ease-in-out;
        will-change: background-color;
        &.scrolled {
          z-index: 1021;
          background-color: #fff;
        }
      }
    }
    min-height: 82px;
    position: relative;
    .navbar-brand img {
      max-height: 40px;
      width: auto;
    }
    .navbar-nav {
      & > .nav-item {
        @media (min-width: 1200px) {
          font-size: 1.25rem;
        }
      }
    }
  }
}
