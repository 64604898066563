.authincation-content {
    height: 100%;
    overflow: auto;
    align-items: center;
    padding: 0;
    background: #1eaae7;
}

.auth-form {
    padding: 10px;
    width: 100%;
    background: #1eaae7;
    border-radius: 15px;
}
.auth-form-1 {
    padding: 30px;
    width: 100%;
    background: #1eaae7;
    border-radius: 15px;
    transform: translateY(50%);
}
.loginBtn {
    border-radius: 50% !important;
}

.title {
    font-size: 1rem !important;
    font-weight: 700 !important;
    color: #fff !important;
}

.loginInput {
    background: #fff !important;
    padding: 0 4px !important;
    border-radius: 10px !important;
    font-size: 15px !important;
    font-weight: 500;
}
/* Pour les ecrans dont la largeur minimal est 320px */
@media (min-width: 320px) {
    .auth-form {
        padding: 10px;
        width: 100% !important;
        margin: 0 auto;
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
        background: #1eaae7;
        border-radius: 10px;
    }
    .auth-form-1 {
        padding: 10px;
        width: 100%;
        background: #1eaae7;
        border-radius: 15px;
        transform: translateY(50%);
    }
}
/* Pour les ecrans dont la largeur minimal est 768px */
@media (min-width: 768px) {
    .authincation-content {
        top: 0;
        height: 100%;
        overflow: auto;
        align-items: center;
        padding: 0;
        background: #1eabe7;
    }
    .auth-form {
        padding: 10px;
        width: 50% !important;
        margin: 0 auto;
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
        background: #1eaae7;
        border-radius: 15px;
    }
    .auth-form-1 {
        padding: 10px;
        width: 60%;
        background: #1eaae7;
        border-radius: 15px;
        margin: 0 auto;
    }
    .title {
        font-size: 1.125rem !important;
        font-weight: 700 !important;
        color: #fff !important;
    }
}
/* Pour les ecrans dont la largeur minimal est 812px */
@media (min-width: 812px) {
    .authincation-content {
        top: 0;
        height: 100%;
        overflow: auto;
        align-items: center;
        padding: 0;
        background: #1eabe7;
    }
    .auth-form {
        padding: 30px;
        margin: 0 auto;
    }
}

/* Pour les ecrans dont la largeur minimal est 1024px */
@media (min-width: 1024px) {
    .authincation-content {
        top: 0;
        height: 100%;
        overflow: auto;
        align-items: center;
        padding: 1rem;
        background: #1eabe700;
    }
    .auth-form {
        padding: 30px;
        width: 40% !important;
        margin: 0 auto;
        -ms-transform: translateY(25%);
        transform: translateY(25%);
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15) !important;
        background: #1eaae7;
        border-radius: 10px;
    }
    .auth-form-1 {
        padding: 30px;
        width: 40%;
        margin: 0 auto;
        background: #1eaae7;
        border-radius: 15px;
        -ms-transform: translateY(35%);
        transform: translateY(35%);
    }
}
@media (min-height: 731px) {
    .auth-form {
        padding: 30px;
        margin: 0 auto;
        -ms-transform: translateY(10%);
        transform: translateY(10%);
    }
}

/* Pour les ecrans dont la hauteur minimal est 812px */
@media (min-height: 812px) {
    .auth-form {
        padding: 30px;
        margin: 0 auto;
        -ms-transform: translateY(20%);
        transform: translateY(20%);
    }
}

@media (min-height: 823px) {
    .auth-form {
        padding: 30px;
        margin: 0 auto;
        -ms-transform: translateY(20%);
        transform: translateY(20%);
    }
}
