footer {
  padding: 3.75rem 0;
  .widget {
    margin-bottom: 50px;
    .nav {
      .nav-link {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .card {
      &.bg-white {
        border-color: rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.05) !important;
      }
      &.card-body {
        padding: 2.25rem;
      }
    }
  }
}

@media (min-width: 768px) {
  footer {
    padding: 6rem 0;
    &.footer-1 hr {
      margin-top: 6rem;
    }
  }
}

@media (min-width: 992px) {
  footer {
    padding: 7.5rem 0;
  }
}

@media (min-width: 768px) {
  .footer-1 hr {
    margin-top: 6rem;
  }
}
