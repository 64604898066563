@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
input {
    font-family: 'Poppins', sans-serif;
}

.containers {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;
    margin: 0 0 0 0 !important;
    padding: 0 !important;
}

.forms-containers {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
}

.form--personel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.form--personel.sign-up-form {
    opacity: 0;
    z-index: 1;
}

.form--personel.sign-in-form {
    z-index: 2;
}

.title {
    font-size: 2.2rem;
    color: #444;
    margin-bottom: 10px;
}

.input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
}

.input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
}

.input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}

.social-text {
    padding: 0.7rem 0;
    font-size: 1rem;
}

.social-media {
    display: flex;
    justify-content: center;
}

.social-icon {
    height: 46px;
    width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.45rem;
    color: #333;
    border-radius: 50%;
    border: 1px solid #333;
    text-decoration: none;
    font-size: 1.1rem;
    transition: 0.3s;
}

.social-icon:hover {
    color: #1eaae7;
    border-color: #1eaae7;
}

.btns {
    width: 150px !important;
    background-color: #1eaae7 !important;
    border: none !important;
    outline: none !important;
    height: 49px !important;
    border-radius: 49px !important;
    color: #fff !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    margin: 10px 0 !important;
    cursor: pointer !important;
    transition: 0.5s !important;
}

.btns:hover {
    background-color: #1eaae7 !important;
}
.panels-containers {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.containers:before {
    content: '';
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background-image: linear-gradient(-45deg, #108ec4 0%, #1eaae7 100%);
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
}

.imageV {
    width: 100%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
}

.left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
}

.right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
}

.panel .content {
    color: #fff;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
}

.panel h3 {
    font-weight: 600;
    line-height: 1;
    font-size: 1.5rem;
}

.panel p {
    font-size: 0.95rem;
    padding: 0.7rem 0;
}

.btns.transparent {
    margin: 0 !important;
    background: none !important;
    border: 2px solid #fff !important;
    width: 130px !important;
    height: 41px !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important;
}

.right-panel .imageV,
.right-panel .content {
    transform: translateX(800px);
}

/* ANIMATION */

.containers.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
}

.containers.sign-up-mode .left-panel .imageV,
.containers.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
}

.containers.sign-up-mode .signin-signup {
    left: 25%;
}

.containers.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
}

.containers.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
}

.containers.sign-up-mode .right-panel .imageV,
.containers.sign-up-mode .right-panel .content {
    transform: translateX(0%);
}

.containers.sign-up-mode .left-panel {
    pointer-events: none;
}

.containers.sign-up-mode .right-panel {
    pointer-events: all;
}

@media (max-width: 870px) {
    .containers {
        min-height: 800px;
        height: 100vh;
    }
    .signin-signup {
        width: 100%;
        top: 95%;
        transform: translate(-50%, -100%);
        transition: 1s 0.8s ease-in-out;
    }

    .signin-signup,
    .containers.sign-up-mode .signin-signup {
        left: 50%;
    }

    .panels-containers {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr 1fr;
    }

    .panel {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 2.5rem 8%;
        grid-column: 1 / 2;
    }

    .right-panel {
        grid-row: 3 / 4;
    }

    .left-panel {
        grid-row: 1 / 2;
    }

    .imageV {
        width: 200px;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.6s;
    }

    .panel .content {
        padding-right: 15%;
        transition: transform 0.9s ease-in-out;
        transition-delay: 0.8s;
    }

    .panel h3 {
        font-size: 1.2rem;
    }

    .panel p {
        font-size: 0.7rem;
        padding: 0.5rem 0;
    }

    .btnstransparent {
        width: 110px !important;
        height: 35px !important;
        font-size: 0.7rem !important;
    }

    .containers:before {
        width: 1500px;
        height: 1500px;
        transform: translateX(-50%);
        left: 30%;
        bottom: 68%;
        right: initial;
        top: initial;
        transition: 2s ease-in-out;
    }

    .containers.sign-up-mode:before {
        transform: translate(-50%, 100%);
        bottom: 32%;
        right: initial;
    }

    .containers.sign-up-mode .left-panel .imageV,
    .containers.sign-up-mode .left-panel .content {
        transform: translateY(-300px);
    }

    .containers.sign-up-mode .right-panel .imageV,
    .containers.sign-up-mode .right-panel .content {
        transform: translateY(0px);
    }

    .right-panel .imageV,
    .right-panel .content {
        transform: translateY(300px);
    }

    .containers.sign-up-mode .signin-signup {
        top: 5%;
        transform: translate(-50%, 0);
    }
}

@media (max-width: 570px) {
    form {
        padding: 0 1.5rem;
    }

    .imageV {
        display: none;
    }
    .panel .content {
        padding: 0.5rem 1rem;
    }
    .containers {
        padding: 1.5rem;
    }

    .containers:before {
        bottom: 72%;
        left: 50%;
    }

    .containers.sign-up-mode:before {
        bottom: 28%;
        left: 50%;
    }
}
